import initTracking from './tracking';

/**
 * This file is built separately from the rest of the app, and exported to
 * `build/tracking.js`, which is then imported to various apps via GTM.
 *
 * The `tracking.ts` file imported above is exports a couple of its functions
 * so that they can be tested in isolation.
 *
 * When this file is built and loaded into the browser, initialise the tracking.
 */
(() => {
  initTracking();
})();
